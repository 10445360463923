label
{
	display:block;
	font-weight:bold;
	padding-left: 20px;
	padding-top: 10px;
	text-align: left;
	text-shadow: 2px 2px 3px rgba(0,0,0,.35);
}

.hilite[type=text],
.hilite[type=password]
{
	background-color: #FFFFA0;
}

input[type=checkbox]
{
	margin-left: 20px;
}

input[type=text],
input[type=password],
input[type=date],
select,
textarea
{
	box-sizing:content-box;
	-ms-box-sizing:content-box;
	-moz-box-sizing:content-box;
	-webkit-box-sizing:content-box; 
	width: 265px;
	padding: 2px;
	margin-left: 20px;
	border: solid 1px #90837a;
	border-radius: 3px;
	font: normal 1em "Trebuchet MS", Tahoma, sans-serif;
	color:#777;
	background-color: #F8F8F8;
}

input:disabled:not([type="button"]):not([type="submit"])
{
	background-color: #999999;
	color: #000000;
}

label+span
{
	margin-left: 20px;
}

input.button
{
	margin: 0;
	font: bold 1em Arial, Sans-serif;
	/*background: #FFF url(gradientbg.jpg) repeat-x;*/
	padding: 2px 3px;
	color: #333;
	border: 1px solid #DADADA;
}

.slidercontainer .sliderbar
{
	display: inline-block;
	margin-left: 25px;
	width: 300px;
}

.datetimecontainer input
{
	position: relative;
	z-index: 2;
}

#ui-datepicker-div
{
	font-size: 10px;
}

.userForm
{
    display: flex;
    /* flex-direction: column; */
    flex-basis: auto;
    justify-content: start;
    flex-wrap: wrap;
    /* align-content: flex-end; */
}

.formgroup
{
    padding-bottom: 50px;
}

.formgroupcolumn
{
    /* width: 45%; */
    margin: 50px;
}

@media only screen and (max-width: 649px)
{
    .userForm
    {
        display: block;
        width: 100%;
    }

    .formgroupcolumn
    {
        width: 100%;
    }
    /* .userForm
    {
        display: flex;
        flex-direction: row;
    } */
}