

html
{
    background: url('../Images/blue_patterns_wallpaper_DARK.jpg') no-repeat fixed top center #000;
    color: #bdc38f;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

.Body
{
    background-image: none;
    background-color: transparent;
}

.Body a, .Body a:visited
{
    color: #edf3bf;
    text-decoration: none;
}

.Body a:hover
{
    text-decoration: underline;
}

p > b
{
    color: #FFCC00;
}

.header
{
    background: url('../Images/HeadBaba_960.png') no-repeat top right;
    height: 160px;
    margin: 0px auto;
    object-fit: scale-down;
    top: 0px;
    /* width: 960px; */
}

.footer
{
    bottom: 10px;
    font-size: 10px;
    left: 50%;
    margin-left: -480px;
    position: absolute;
    text-align: center;
    width: 960px;
}

.linkable
{
    height: 50px;
}

#introduce
{
    display: inline-block;
    margin: 0px;
    margin-right: 15px;
    vertical-align: top;
    width: 462px;
}

#introduce .linkable
{
    background: url('../Images/Introductions.png') no-repeat center;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    -o-user-select: none;
    user-select: none;
}

form
{
    text-align: center;
}

form label
{
    display: block;
    /* font-size: 12px; */
    font-weight: bold;
    margin-left: 0px;
    margin-top: 15px;
    padding-left: 0px;
    text-align: center;
}

form input
{
    background-color: rgba(255,255,255,.75);
    border: solid 1px #90837a;
    border-radius: 3px;
    font-size: 18px;
    margin-left: 0px;
    padding: 2px;
    width: 275px;
}

form input[type='text'], form input[type='password']
{
    margin-left: 0px;
}

form input[type='submit']
{
    background-color: #000066;
    border: solid 1px #3333AA;
    color: #6666CC;
    display: block;
    margin: 20px auto;
    padding: 2px 10px;
    width: auto;
}   
    
form input[type='submit']:disabled
{   
    color: #000033;
}

.deptlist
{
	align-items: center;
	display: flex;
	padding: 100px 2em;
	/*flex-direction: column;*/
}

.error
{
    background-color: #FF9999;
    border: solid 2px #993333;
    color: #000000;
    font-weight: bold;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    width: 25%;
}

.Page
{
    width: 100vw;
}