/*.Body
{
	padding: 0;
	margin: 0;
	overflow: scroll;
	width: 100%;
}*/

.dgshiftheader
{
	border-radius: 5px 5px 0px 0px;
    background-color: rgba(155,160,155,.7);
	border: solid 1px #000000;
    border-bottom: 0px;
	color: #000000;
}

.dgdate
{
	user-select: none;
}

.dgshift li:nth-child(even)
{
    background-color: rgba(60, 60, 60, .1);
}


@media only screen and (max-width: 649px)
{
    .dgdateheader
    {
        background-color: #707070;
    }

    .dgshift li
    {
        padding: 5px 0px;
    }

}

@media only screen and (min-width: 650px)
{
}