*
{
	-webkit-overflow-scrolling: touch;
	/*background-color: #E0E3E3;*/
	/* box-sizing: border-box; */
	font-family: Arial, Helvetica, sans-serif;
}

/*
span .bm-burger-bars {
    background: #000;
}
*/

body
{
}

.bm-menu li
{
    list-style-type: none;
}

.bm-menu li span
{
    color: #000000;
}

.bm-item
{
    margin: 0px;
    padding: 0px;
}


.banner
{
	background-color: #777777;
	/*color: #000000;*/
	display: block;
	font-weight: bold;
	height: 75px;
	line-height: 75px;
	padding-left: 75px;
    /* position: sticky; */
    overflow: hidden;
    position: fixed;
    width: 100%;
	top: 0;
	left: 0;
	width: 100%;
	/* text-shadow: 1px 4px 4px #555; */
  	/* color: rgba(0,0,0,1); */
  	/*border: ridge 5px darkgrey;*/
	/*font-size: 24px;*/
	font-size: calc(24px + (26 - 16) * ((100vw - 320px) / (1920 - 320))); /* Adjust the values as needed */
	z-index: 99;
}

h1
{
    font-family: headerFont;
    font-size: 40px;
    font-weight: normal;
    letter-spacing: 1px;
    margin: 0px;
    /* padding-left: 25px; */
    /* position: absolute; */
    top: 0px;
    /* left: 215px; */
    /* width: 745px; */
    z-index: 3;
}

.Page
{
    display: flex;
    flex-direction: row;
}

.sidebarWrapper
{
    flex: 0 0 250px;
}

.bodyWrapper
{
    flex-grow: 1;
}

.Body
{
    margin-top: 75px;
}

/*
.Page
{
    margin: 0px auto;
    width: 100%;
    overflow: hidden;
}

.Body
{
    margin: 75px auto;
    overflow: scroll;
}

.pageWrapper
{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
}
*/
/*
.banner::after
{
	display: block;
}
*/

/*
.Body
{
	overflow: scroll;
	padding-top: 6em;
	top: 6em;
	width: 100%;
	height: 100%;
}
*/

@media only screen and (min-width: 768px)
{
}