.deptgroup
{
    width: 100%;
}

.dgheader
{
    background-color: #000;
    color: #fff;
    display: block;
    height: 15%;
    padding: 30px;
    margin: 0px;
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 9;
}

.dgdates
{
    top: 10px;
    height: 90%;
    display: sticky;
    padding: 0;
    margin: 0;
}

