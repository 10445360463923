/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 23px;
    top: 23px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;

  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    /* background: #373a47; */
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    /* color: #b8b7ad; */
    /* padding: 0.8em; */
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Individual item */
    /* color: #d1d1d1; */
    color: #000;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  
}
  
/* Styling of overlay */
.bm-overlay
{
    background: rgba(0, 0, 0, 0.3);
}

/* all-items when hovering */
/* .bm-item:hover */
.bm-menu a:hover
{
    /* color: #cccccc; */
    text-decoration: underline;
    text-shadow: 2px 2px 3px #777;
    transition: color .2s ease;
}

/* .bm-item a, .bm-item a:visited */
.bm-menu a, .bm-menu a:visited
{
    text-decoration: none;
    color: #492E00;
}

.bm-item a:hover
{
    text-decoration: underline;
    text-shadow: 2px 2px 3px #666;
    transition: color 0.2s ease;
}

@media only screen and (min-width: 768px)
{
    /*
    .pageWrapper
    {
        height: 100vh;
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 30px 1fr 30px;
    }
    */

    .bm-menu
    {
        height: 100vh;
        position: fixed;
        padding-top: 100px;
        width: 200px;
    }

    .bm-menu > ul
    {
        padding-left: 0;
        margin-left: 0;
        height: 100%;
        overflow: auto;
    }
}
