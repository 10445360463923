table
{
    padding-top: 25px;
	text-align: center;
	/* width: 50%; */
}

input,select
{
    margin-left: 0px;
}

th
{
    /*background: url('Images/Header_BG_01.jpg') 0 90%;*/
    color: #f8f8f8;
}

td
{
    padding: 0px 15px;
}

td:last-child, td:last-child
{
    text-align: center;
    text-transform: uppercase;
}

.sv-incomplete, .sv-confirmed, .sv-unconfirmed
{
    color: #909090;
}

.sv-notworked
{
    color: #903030;
    font-weight: bold;
    text-decoration: line-through;
}

.deptPageMySchedule td:last-child.worked
{
    background-color: rgba(192,255,192,.25);
    border: 1px solid rgba(0,150,0,.5);
    color: #009900;
    font-weight: bold;
}

.deptPageMySchedule td:last-child.notworked
{
    background-color: rgba(255,192,192,.25);
    border: 1px solid rgba(150,0,0,.4);
    color: #990000;
    font-weight: bold;
}

.deptPageMySchedule td:last-child.pending
{
    background-color: rgba(192,192,255,.25);
    border: 1px solid rgba(0,0,150,.5);
    color: #6060C0;
    font-weight: normal;
}

.deptPageMySchedule td:last-child.confirmed
{
	font-weight: bold;
}

.deptPageMySchedule td:last-child.unconfirmed
{
	color: #555555;
}


@media only screen and (max-width: 649px)
{
	table
	{
		width: 100%;
	}

	th
	{
		display: none;
	}

	tr
	{
		display: block;
		padding-bottom: 25px;
	}

	.shiftDate
	{
		color: #f8f8f8;
		display: inline-block;
		font-weight: bold;
	}
	.shiftTime
	{
		color: #f8f8f8;
		font-weight: bold;
		display: inline-block;
	}
	select 
	{
		font-size: 30px;
		margin: 0 !important;
	}

	.deptPageBody:nth-child(1)
	{
		/* margin: 0;
		padding: 0; */
		text-align: center;
	}
	.groupName, .roleDesc
	{
		width: 33%;
	}

}