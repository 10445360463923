.dgavails
{
    border: 1px solid #333366;
	border-radius: 0px 0px 5px 5px;
    font-size: .9em;
    margin: 0px;
    padding: 0px;
}

.dgavails li
{
    display: flex;
    list-style: none;
	margin: 2px 1px;
}

.dgavails li span
{
    display: inline-block;
    height: 100%;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.dgavails-role
{
    background-color: #666666;
    color: #ffffff;
    width: 25%;
}

.dgavails-user
{
	color: #000000;
    width: 75%;
}

.dgavails-clickable
{
    color: #339933;
    font-weight: bold;
    text-decoration: underline;
}

.dgavails-clickable:hover
{
    background-color: rgba(255,255,255,.8);
    color: #336633;
    cursor: pointer;
}