.dgshift
{
    /* background-color: #cccccc; */
    vertical-align: middle;
}

/*
.dgdateshifts
{
    margin-left: 25px;
}
*/