@media only screen and (max-width: 649px)
{
	.dgdate
	{
	    /*padding: 0px 10px;*/
		text-align: center;
	    padding: 0px;
	    border-bottom: 1px solid #ffffff;
	    display: block;
	    height: 100%;
		/* margin: 25px; */
	    min-height: 100% !important;
	}

	.dgdateheader
	{
		color: #000;
		display: block;
		font-weight: bold;
		/*height: 10%;*/
		padding: 10px;
		margin: 0px;
        margin-right: 25px;
		position: sticky;
		text-align: center;
		/*top: 100px;*/
		top: 75px;
		width: 100%;
		z-index: 8;
	}

	.dgdateshifts
	{
		padding: 15px;
	}

	.dgshift
	{
		border-radius: 5px;
		display: inline-block;
		/* margin: 0px 25px; */
        margin: 0px;
        margin-bottom: 25px;
		/* width: 250px; */
        width: 80%;
	}
}

/*
.dgdateheader {
    display: inline-block;
    font-weight: bold;
    height: 100%;
    margin: 25px;
    vertical-align: middle;
    width: 20%;
}
*/


@media only screen and (min-width: 650px)
{
	.dgdate
	{
		border-bottom: 1px solid #ffffff;
        /* display: block; */
		padding: 25px 25px 0px 25px;
	}

	.dgdateheader
	{
		display: inline-block;
		font-weight: bold;
		height: 100%;
		text-align: center;
		vertical-align: top;
		width: 35px;
	}

	.dgdateshifts
	{
		display: inline-block;
		/* padding: 0px 50px; */
		/* width: 100%; */
		z-index: -2;
	}
	
	.dgshift
	{
		border-radius: 5px;
		display: inline-block;
		/*margin: 25px;*/
		/* margin: 0px 25px 25px 25px; */
        margin: 0px 0px 25px 25px;
		width: 200px;
	}

    .dgshiftheader
    {
        /* background-color: rgba(192, 192, 192, .25); */
    }
}

.dgshiftheader
{
    /*
	border-radius: 5px 5px 0px 0px;
	background-color: #333366;
	border: #333366;
    background-color: rgba(155,160,155,.7);
	color: #ffffff;
    */
	font-weight: bold;
	padding: 4px 2px;
	text-align: center;
}